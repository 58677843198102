export default function initialize() {
  jQuery(() => {
    const contactNode = jQuery('#contact-app .root').get(0);
    const clubAppNode = document.getElementById('club-app');
    const pageNode = document.getElementById('builder-page');

    if (pageNode) {
      import(/* webpackChunkName: "builder" */'./builder')
          .then((initializeBuilder) => {
            initializeBuilder.default(pageNode);
          });
    }

    if (contactNode) {
      import(/* webpackChunkName: "contact" */'./contact-sidebar')
        .then((initializeContact) => {
          initializeContact.default(contactNode);
        });
    }

    if (clubAppNode) {
      import(/* webpackChunkName: "kcm" */'./kcm-dashboard')
        .then((initializeClubApp) => {
          initializeClubApp.default(clubAppNode);
        });
    }
  });
}

initialize();
